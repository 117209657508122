import "./styles.scss"

import React, { useState } from "react"
import axios from "axios"
import { navigate } from "gatsby"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"

const ContactForm = () => {
  const formID = "5"
  const [send, setSend] = useState(false)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [legalFirst, setLegalFirst] = useState(false)
  const [legalSecond, setLegalSecond] = useState(false)

  const isBrowser = typeof window !== "undefined"
  const location = isBrowser ? window.location.href : null

  const resetForm = () => {
    setSend(false)
    setName("")
    setEmail("")
    setPhone("")
    setMessage("")
    setLegalFirst(false)
    setLegalSecond(false)
  }

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("message", message)
    formData.set("legalFirst", legalFirst)
    formData.set("legalSecond", legalSecond)
    formData.set("pageurl", location)

    axios({
      method: "post",
      url: `https://mazowiecka115.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")
        resetForm()
        navigate("/kontakt-potwierdzenie/")
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <section className="contact-form" id="contact">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-4 col-md-5">
            <h2 className="contact-form__header">Biuro sprzedaży</h2>

            <div className="contact-form__info">
              <address>
                ul. Głowackiego 60
                <br />
                30-086 Kraków
                <br />
                <br />
                <a href="mailto:kontakt@mazowiecka115.pl">
                  kontakt@mazowiecka115.pl
                </a>
                <strong>Godziny pracy:</strong>
                poniedziałek - piątek:
                <br />
                9.00-17.00
              </address>
            </div>

            <div className="contact-form__info">
              <address>
                <strong>
                  Zapraszamy również do umówienia <br />
                  innego, dogodnego dla Państwa <br /> terminu spotkania.
                </strong>
                <a href="tel:+48531553839">+48 531 553 839</a>
                <a href="mailto:klaudia@mazowiecka115.pl">
                  klaudia@mazowiecka115.pl
                </a>
              </address>
            </div>
          </div>

          <div className="col-md-7">
            <div className="contact-form__form">
              <h2>Skontaktuj się z nami</h2>
              <h4>Poznaj swój nowy dom</h4>

              <form onSubmit={formSubmit}>
                <Input
                  label="Imię i nazwisko"
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />

                <div className="row">
                  <div className="col-md-6">
                    <Input
                      label="Telefon"
                      type="phone"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      label="E-mail"
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>

                <Input
                  label="Wiadomość"
                  type="textarea"
                  id="message"
                  name="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  rows="4"
                />

                <Checkbox
                  name="legalFirst"
                  checked={legalFirst}
                  onChange={() => setLegalFirst(!legalFirst)}
                  required
                >
                  Wyrażam dobrowolną zgodę na przetwarzanie moich danych
                  osobowych przez WS KRAK Sp. z o.o. z siedzibą w Krakowie ul.
                  Kronikarza Galla 5/1, KRS: 0000940427 (Administrator danych),
                  w celu marketingu bezpośredniego dotyczącego własnych
                  produktów i usług. Obowiązek informacyjny zgodnie z art. 13
                  ogólnego rozporządzenia o ochronie danych osobowych (RODO) z
                  dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016):
                  informacje o przetwarzaniu przez nas danych, jak również o
                  prawach osób, których dane dotyczą znajdują się w naszej{" "}
                  <a href="/polityka-prywatnosci/" target="_blank">
                    polityce prywatności
                  </a>
                  .
                </Checkbox>

                <Checkbox
                  name="legalFirst"
                  checked={legalSecond}
                  onChange={() => setLegalSecond(!legalSecond)}
                >
                  Wyrażam zgodę na przesyłanie na podany przeze mnie adres
                  poczty elektronicznej informacji handlowych zgodnie z
                  przepisami ustawy z dnia 18 lipca 2002 r. o świadczeniu usług
                  drogą elektroniczną przyjmując jednocześnie do wiadomości, że
                  niniejsza zgoda może być odwołana w każdym czasie.
                </Checkbox>

                <div className="contact-form__action">
                  <Button type="submit" disabled={!legalFirst}>
                    {send === true ? "wysyłanie.." : "wyślij"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
